



































































































import {
  Component, Mixins, Prop,
} from 'vue-property-decorator';
import { Action } from 'vuex-class';

import _ from 'lodash';

import Loan from '@/entities/Loan';

import LoansList from '@/views/loans/LoansList.vue';
import LoansDetail from '@/views/loans/LoansDetail.vue';
import LoanService from '@/services/loans';

import FontCalculation from '@/mixins/FontCalculation.vue';
import UserPermissions from '@/mixins/UserPermissions.vue';

@Component({
  name: 'loans',
  components: {
    LoansList,
    LoansDetail,
  },
})
export default class Loans extends Mixins(FontCalculation, UserPermissions) {
  @Prop({
    type: String,
  }) private readonly view!: string;

  @Prop({
    type: Boolean,
    default: true,
  }) private readonly showTitle!: boolean;

  @Action('showSuccess') showSuccess!: (val: any) => {};

  private loans: Loan[] = [];
  private currentView = 0;

  private isLoading = false;

  private errorMessage = 'Unable to create new loan. Please try again later.';

  private loanService: LoanService = new LoanService();
  private loan: Loan = null;

  private errorDialog = false;
  private newLoan = false;
  private loanNumber = '';
  private lenderNumber = '';
  private borrower1 = '';
  private escrowType = '';
  private companyName = '';
  private rules: any = {
    required: (value: any) => !!value || 'Required.',
  };

  // Computed
  get selectedLoan(): boolean {
    return Boolean(this.$route.params.id);
  }

  get hasTitle(): boolean {
    return this.view !== 'panel-wall' && this.showTitle;
  }

  async createLoan(loanNumber: string, lenderNumber: string, borrower1: string, companyName: string) {
    if (!(this.$refs.form as any).validate()) {
      return;
    }

    this.isLoading = true;

    const payload = {
      loan_number: loanNumber,
      lender_number: lenderNumber,
      loan_type: '',
      company_name: companyName,
      borrower_name: borrower1,
    };

    try {
      const loanResponse = await this.loanService.createLoan(payload);
      this.newLoan = false;
      if (loanResponse.loanId !== undefined) {
        this.loanNumber = '';
        this.lenderNumber = '';
        this.escrowType = '';
        this.companyName = '';
        this.borrower1 = '';
        this.handleSelectedLoan(loanResponse, true);
      }
    } catch (e) {
      console.log(e.response);
      if (e.response && e.response.status >= 400) {
        this.errorMessage = e.response.data.message;
        this.errorDialog = true;
      }
    } finally {
      this.isLoading = false;
    }
  }

  handleSelectedLoan(loan: Loan, edit = false) {
    if (this.view === 'panel-wall') {
      this.loan = loan;
    } else {
      const queryParams = edit ? { editMode: edit.toString() } : {};
      this.$router.push({ path: `/loans/${loan.loanId}`, query: queryParams });
    }
  }

  clearSelection(deletedLoan?: Loan) {
    if (deletedLoan) {
      this.showSuccess(`Successfully deleted loan <b>${deletedLoan.name}</b>`);
    }

    if (this.view === 'panel-wall') {
      this.loan = null;
    } else {
      this.$router.push({ path: '/loans' });
    }
  }
}
