












































































import _ from 'lodash';
import {
  Component, Prop, Vue, Emit, Mixins,
} from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import Loan from '@/entities/Loan';

import LoansService from '@/services/loans';

import UserPermissions from '@/mixins/UserPermissions.vue';

type PermissiveDataTableHeader = DataTableHeader & {
  roles?: string[]
}

@Component({
  name: 'loans-list',
})
export default class LoansList extends Mixins(UserPermissions) {
  @Prop({
    type: Number,
    default: 1,
  }) private readonly fontSize!: any;

  private loanSearch: string = '';
  private totalLoans = 0;
  private isLoading = true;
  private currentOptions: any = {};
  private currentSearch = '';
  private currentDebounce: Function = null;

  private showInactiveLoans: boolean = false;
  private matchExactLoan: boolean = false;

  private loans: Loan[] = [];

  private service: LoansService = new LoansService();

  private columns: PermissiveDataTableHeader[] = [
    { text: 'Loan Number', value: 'loanNumber' },
    { text: 'Lender Number', value: 'lenderNumber' },
    { text: 'Added Date', value: 'dateAdded', roles: ['Capital Trainee', 'Capital User', 'Capital Admin'] },
    { text: 'Name', value: 'borrowerName' },
    { text: 'Company Name', value: 'companyName' },
    { text: 'Escrow Type', value: 'loanType' },
    { text: 'Active', value: 'active' },
  ];

  private searchCriteria: DataTableHeader[] = [
    {
      text: 'All',
      value: 'all',
    },
    {
      text: 'Loan Number',
      value: 'loan_number',
    },
    {
      text: 'Lender Number',
      value: 'lender_number',
    },
    {
      text: 'Added Date',
      value: 'date_added',
    },
    {
      text: 'Names',
      value: 'name_and_name_2',
    },
    {
      text: 'Company Name',
      value: 'company_name',
    },
    {
      text: 'Address',
      value: 'address',
    },
    {
      text: 'Parcel Number',
      value: 'parcel_number',
    },
  ];

  private currentSearchBy: DataTableHeader = this.searchCriteria[0];

  // Hooks
  async created() {
    this.totalLoans = 0;
  }

  async updateOptions(options: any) {
    this.isLoading = true;
    this.currentOptions = options;
    this.getLoans();
  }

  async searchFieldUpdated(search: string) {
    let shouldGetLoans = true;
    if (this.currentSearch.length === 0 && search.length === 1) {
      shouldGetLoans = false;
    }
    if (this.currentSearch.length === 1 && search.length === 0) {
      shouldGetLoans = false;
    }
    this.currentSearch = search;
    if (shouldGetLoans) {
      this.currentOptions.page = 1;
      this.getLoans();
    }
  }

  async updateSearchBy(resetExact: boolean = true) {
    if (resetExact) {
      this.matchExactLoan = false;
    }

    this.currentOptions.page = 1;
    this.getLoans();
  }

  async getLoans() {
    this.isLoading = true;

    if (!this.currentDebounce) {
      this.currentDebounce = _.debounce(this.searchForLoans, 500);
    }

    this.currentDebounce();
  }

  async searchForLoans() {
    const params: any = {
      limit: this.currentOptions.itemsPerPage,
      offset: (this.currentOptions.page - 1) * this.currentOptions.itemsPerPage,
      sort_by: 'loanNumber',
      order_by: 'asc',
      show_inactive_loans: this.showInactiveLoans,
      show_inactive_parcels: this.showInactiveLoans,
    };

    if (this.currentSearch && this.currentSearch.length > 1) {
      params.search_field = this.currentSearchBy.value;
      params.search_value = this.currentSearch;
      params.search_exact = this.matchExactLoan;
    }

    if (this.currentOptions.sortBy.length > 0) {
      [params.sort_by] = this.currentOptions.sortBy;
      params.order_by = this.currentOptions.sortDesc[0] ? 'desc' : 'asc';
    }

    try {
      const response = await this.service.getAllLoans(params);
      this.totalLoans = response.count;
      this.loans = response.results;
    } catch (e) {
      this.loans = [];
    }
    this.isLoading = false;
  }

  // Computed
  get userColumns(): DataTableHeader[] {
    return this.columns.filter((column) => !column.roles || this.hasRole(column.roles));
  }

  @Emit('select')
  handleRowClick(loan: Loan) {
    return loan;
  }

  toggleLoanCheckbox() {
    if (this.matchExactLoan) {
      this.currentSearchBy = {
        text: 'Loan Number',
        value: 'loan_number',
      };
    }
    this.currentOptions.page = 1;
    this.getLoans();
  }
}
